import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import { CrudProperty } from "../plugins/Cruxtify/CrudProperty";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  BooleanField,
  DateField,
  DateTimeField,
  MoneyField,
  NumberField,
  RelationshipField,
  RelationshipFieldMany,
  LongTextField,
} from "../plugins/Cruxtify/field-types";
import {
  BooleanProperty,
  DateProperty,
  DateTimeProperty,
  MoneyProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

export class Invoice extends CrudModel {
  protected static _typeLabel = "Invoice";
  protected static asProperty = "invoice";
  public static api = {
    path: "invoices",
  };
  protected static routeBase = "invoices";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: StringProperty,
      opts: {
        name: "id",
        label: "Invoice Number",
        userPermissions: UserPermission.Read,
        decorator: (val) => "INV-" + String(val).padStart(6, "0"),
        computed: true,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Created At",
        userPermissions: UserPermission.Read,
        sortable: true,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Updated At",
        userPermissions: UserPermission.Read,
        sortable: true,
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contract",
        label: "Contract",
        relatedModel: "Contract",
        sortable: true,
      },
    },
    {
      type: DateProperty,
      opts: {
        name: "date",
        label: "Invoice Date",
        sortable: true,
        userPermissions: UserPermission.Edit,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "age",
        label: "Age",
        sortable: true,
        computed: true,
        userPermissions: UserPermission.Read,
        decoratorSuffix: " days",
      },
    },
    {
      type: MoneyProperty,
      opts: {
        name: "total",
        label: "Invoice Total",
        sortable: true,
        default: (prop: CrudProperty) => {
          const contract = prop.model?.getProperty("contract").typedValue;
          if (!contract) return undefined;

          return contract.getProperty("estimate").typedValue;
        },
      },
    },
    {
      type: MoneyProperty,
      opts: {
        name: "down_payment",
        label: "Down Payment",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "notes",
        label: "Invoice Notes",
        description: "Notes will appear on the invoice PDF.",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "payments",
        label: "Payments",
        relatedModel: "Payment",
        foreignProperty: "invoice",
        userPermissions: UserPermission.Edit,
      },
    },
    {
      type: MoneyProperty,
      opts: {
        name: "due",
        label: "Due",
        isRemotelyComputed: true,
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "paid",
        label: "Paid",
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "uncollectible",
        label: "Uncollectible",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "contact",
        label: "Contact",
        relatedModel: "Contact",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "last_mod_author",
        label: "Last Updated By",
        relatedModel: "User",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "author",
        label: "Author",
        relatedModel: "User",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Owner",
        relatedModel: "User",
        sortable: true,
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "notifications",
        label: "Notifications",
        relatedModel: "Notification",
        foreignProperty: "invoice",
        userPermissions: UserPermission.Read,
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        isPrimaryLabelField: true,
        userPermissions: UserPermission.Read,
        cols: 12,
      },
    },
    {
      type: NumberField,
      opts: {
        property: "legacy_id",
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "contract",
      },
    },
    {
      type: DateField,
      opts: {
        property: "date",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "age",
      },
    },
    {
      type: MoneyField,
      opts: {
        property: "total",
      },
    },
    {
      type: MoneyField,
      opts: {
        property: "down_payment",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "payments",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "asRelationship",
      },
    },
    {
      type: MoneyField,
      opts: {
        property: "due",
      },
    },
    {
      type: BooleanField,
      opts: {
        property: "paid",
      },
    },
    {
      type: LongTextField,
      opts: {
        property: "notes",
      },
    },
    {
      type: BooleanField,
      opts: {
        property: "uncollectible",
      },
    },

    {
      type: RelationshipField,
      opts: {
        property: "contact",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "last_mod_author",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "author",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "user",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "notifications",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "asRelationship",
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      headerComponents: ["InvoicePDFButton"],
      fieldSets: [
        {
          id: "0",
          md: 4,
          xl: 5,
          fields: ["id", "contact", "contract", "date", "age", "user"],
        },
        {
          id: "payments",
          md: 8,
          xl: 7,
          fields: [
            "total",
            "due",
            "paid",
            "uncollectible",
            "payments",
            "notes",
          ],
        },
        {
          id: "notifications",
          cols: 12,
          fields: ["notifications"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: [
        "id",
        "contract",
        "contact",
        "date",
        "total",
        "due",
        "paid",
        "uncollectible",
        "user",
      ],
      enableSearch: true,
      useModal: true,
      filterFields: ["contract", "contact", "uncollectible", "user"],
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },

    {
      id: "asRelationship",
      columnFields: ["id", "date", "total", "paid", "user"],
    },
  ];

  public static balancesDue(query) {
    return this.$nuxt.$api.GET("balances-due", query).then((res) => {
      return {
        ...res,
        dataHydrated: res.data.map((data) => new this(data)),
      };
    });
  }

  public static balancesDueUncollectable(query) {
    return this.balancesDue({
      ...query,
      "include-uncollectable": true,
    });
  }
}
