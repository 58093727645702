import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { DateTimeField } from "../plugins/Cruxtify/field-types/DateTimeField";
import { LongTextField } from "../plugins/Cruxtify/field-types/LongTextField";
import { NumberField } from "../plugins/Cruxtify/field-types/NumberField";
import { RelationshipField } from "../plugins/Cruxtify/field-types/RelationshipField";
import { RelationshipFieldMany } from "../plugins/Cruxtify/field-types/RelationshipFieldMany";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";

import { AddressFieldsEnum } from "../plugins/Cruxtify/field-types/AddressField";
import { GoogleAddressField } from "../plugins/Cruxtify/field-types/GoogleAddressField";
import { UniqueField } from "../plugins/Cruxtify/field-types/UniqueField";
import { PhoneField } from "../plugins/Cruxtify/field-types/PhoneField";
import {
  DateTimeProperty,
  NumberProperty,
  PhoneProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import { Contract } from "./Contract";
import { Contact } from "./Contact";

export class Account extends CrudModel {
  protected static _typeLabel = "Account";
  protected static asProperty = "account";
  public static api = {
    path: "accounts",
  };
  protected static routeBase = "accounts";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "name",
        isPrimaryLabelField: true,
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "address",
        label: "Street",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "city",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "state",
        label: "State",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "zip",
        label: "Zip",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "country",
        label: "Country",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "latitude",
        label: "Latitude",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "longitude",
        label: "Longitude",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: PhoneProperty,
      opts: {
        name: "phone",
        label: "Phone",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "fax",
        label: "Fax",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "description",
        label: "Description",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "contacts",
        apiname: "contact_ids",
        label: "Contacts",
        relatedModel: "Contact",
        foreignProperty: "account",
        tableFields: ["first_name", "last_name", "phone", "created_at"],
        userPermissions: UserPermission.Edit,
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "attachments",
        apiname: "attachment_ids",
        label: "Files",
        relatedModel: "Attachment",
        foreignProperty: "account",
        tableFields: ["name", "created_at"],
        userPermissions: UserPermission.Edit,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "last_mod_author",
        label: "Last Updated By",
        relatedModel: "User",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "author",
        label: "Author",
        relatedModel: "User",
        userPermissions: new UserPermissions(
          // {
          //   [UserRole.SuperAdmin]: UserPermission.Edit
          // },
          UserPermission.Read
        ),
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Owner",
        relatedModel: "User",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
        rules: ["required"],
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    { type: NumberField, opts: { property: "legacy_id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    {
      type: UniqueField,
      opts: {
        label: "Account Name",
        uniqueType: "Account Name",
        id: "unique_name",
        isPrimaryLabelField: true,
        subFields: [
          {
            type: StringField,
            opts: {
              property: "name",
            },
          },
        ],
      },
    },
    {
      type: UniqueField,
      opts: {
        apiMethodName: "getUniqueAddress",
        id: "unique_address",
        fieldWidth: "full",
        label: "Address",
        seamless: true,
        subFields: [
          {
            type: GoogleAddressField,
            opts: {
              id: "address_group",
              label: "Address",
              seamless: true,
              disabledSubFields: [
                AddressFieldsEnum.STREET_2,
                AddressFieldsEnum.COUNTRY,
                AddressFieldsEnum.DIVISION_2,
                AddressFieldsEnum.LATITUDE,
                AddressFieldsEnum.LONGITUDE,
              ],
              subFieldOpts: {
                [AddressFieldsEnum.STREET_1]: {
                  property: "address",
                  id: "address",
                },
                [AddressFieldsEnum.CITY]: { property: "city", id: "city" },
                [AddressFieldsEnum.DIVISION_1]: {
                  property: "state",
                  id: "state",
                },
                [AddressFieldsEnum.POSTAL_CODE]: {
                  property: "zip",
                  id: "zip",
                },
              },
            },
          },
        ],
      },
    },

    {
      type: UniqueField,
      opts: {
        apiMethodName: "getUniquePhone",
        id: "unique_phone",
        label: "Phone",
        seamless: true,
        subFields: [
          {
            type: PhoneField,
            opts: { property: "phone", md: 6, rules: ["usPhone"] },
          },
        ],
      },
    },
    {
      type: StringField,
      opts: { property: "fax" },
    },
    { type: LongTextField, opts: { property: "description" } },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "contacts",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "attachments",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "asRelationship",
      },
    },
    { type: RelationshipField, opts: { property: "last_mod_author" } },
    { type: RelationshipField, opts: { property: "author" } },
    { type: RelationshipField, opts: { property: "user" } },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["created_at", "name", "city", "state", "phone", "user"],
      enableSearch: true,
      filterFields: ["city", "user"],
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];

  public static getUniqueAddress(query = {}) {
    return Contract.getUniqueAddress(query);
  }

  public static getUniquePhone(query = {}) {
    return Contact.getUniquePhone(query);
  }
}
